<template>
    <div class="fill-height container--fluid" style="width: 100%">
        <div class="fill-height container--fluid">
            <v-toolbar flat tile>
                <v-btn icon v-on:click="goBack">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>Ir atrás</v-toolbar-title>
                <v-spacer/>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               icon
                               plain
                               large
                               @click="openDialog">
                            <v-icon color="primary" size="44px">mdi-bookmark-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Me Interesa</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               icon
                               plain
                               large
                               @click="sendPreference('like')">
                            <v-icon color="red" size="44px">mdi-heart</v-icon>
                        </v-btn>
                    </template>
                    <span>Me Gusta</span>
                </v-tooltip>
            </v-toolbar>
        </div>
        <v-container class="fill-height" fluid>
            <!--<v-container fill-height fluid text-xs-center class="mx-3">-->
            <v-row align="start" justify="start">
                <v-col cols="12" md="5">
                    <v-container class="mx-0" fluid>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="text-h5 font-weight-ligh headline text-uppercase">
                                  {{ profile.name }}
                                </span>
                            </v-col>
                            <v-col cols="12">
                                <div class="boxdivider"></div>
                            </v-col>
                            <v-col cols="12">
                                <v-carousel
                                    class="mt-5"
                                    cycle
                                    hide-delimiters>
                                    <v-carousel-item
                                        v-for="item in profile.pictures"
                                        :key="item.id">
                                        <v-img
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            :src="item.url"
                                            class="white--text justify-center elevation-0"
                                            contain
                                            width="100vw"
                                        ></v-img>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col cols="12">
                                <v-container class="pa-0 mt-1" fluid>
                                    <div :id="containerID" :style="containerCSS"></div>
                                </v-container>
                            </v-col>
                            <v-col cols="12">
                                <v-container v-if="profile.youtubeprofile != null" class="pa-0 mt-1" fluid>
                                    <iframe
                                        :src="youtubeVideo"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        frameborder="0"
                                        height="300"
                                        width="100%">
                                    </iframe>
                                    <!--</v-row>-->
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
                <v-col cols="12" md="7">
                    <v-card class="ml-1" elevation="0" ou>
                        <v-container class="mx-0" fluid>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Descripción: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-format-quote-close</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.description }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Dirección: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-map-marker</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.address }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">E-mail: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-email</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.email }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Teléfonos: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-phone</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                <!--{{ profile.phone1 | formatPhone('MX') }}, {{ profile.phone2 || "NO" | formatPhone('US') }}-->
                                                {{
                                                    profile.phone1 | formatPhone('MX')
                                                }}, {{ profile.phone2 | formatPhone('US') }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Número de Licencia Profesional: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-certificate</v-icon>
                                        </v-col>
                                        <v-col>
                                <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                    {{ profile.profesional_license_number }}
                                </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span
                                        class="text-button font-weight-medium">Número de Licencia de la Especialidad: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-certificate</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.specialty_license_number }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Certificación Válida: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-certificate</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.certification_validity }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Certificado Número: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-certificate</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.certificate_number }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Verificación de Licencia Profesional: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-certificate</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.professional_license_verification }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Horarios de Atención: </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-icon color="primary">mdi-clock</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                                                {{ profile.servicehours }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span class="text-button font-weight-medium">Medios Sociales:</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align-content="strech" justify="start">
                                        <v-col cols="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :href="profile.facebookprofile"
                                                        color="blue"
                                                        icon
                                                        target="_blank">
                                                        <v-icon color="primary" size="24px">mdi-facebook</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Facebook</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :href="profile.instagramprofile"
                                                        color="blue"
                                                        icon
                                                        target="_blank">
                                                        <v-icon color="primary" size="24px">mdi-instagram</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Instagram</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :href="profile.tweeterprofile"
                                                        color="blue"
                                                        icon
                                                        target="_blank">
                                                        <v-icon color="primary" size="24px">mdi-twitter</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Tweeter</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :href="profile.youtubeprofile"
                                                        color="blue"
                                                        icon
                                                        target="_blank">
                                                        <v-icon color="primary" size="24px">mdi-youtube</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Youtube</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row align="start" justify="start">
                                        <v-col cols="12">
                                            <div v-if="profile.specialties">
                                                <span class="text-button font-weight-medium">Especialidades:</span><br/>
                                                <v-chip v-for="(s, i) in profile.specialties" :key="i" class="mr-1">
                                                    {{ s.name }}
                                                </v-chip>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="fill-height text-center mx-0" fluid>
            <v-row>
                <v-col cols="12">
                    <v-sheet
                        class="mx-0"
                        max-width="100%">
                        <v-img class="grey lighten-2" height="470"
                               src="@/assets/images/pexels-photo-127873.png" width="100%">
                            <v-container bottom-gradient class="ma-0" fill-height fluid>
                                <v-row align="center" class="mt-3 text-center" justify="center">
                                    <v-col>
                                        <span class="text-h6 text-md-h4 text-uppercase white--text font-weight-bold">Nuestro equipo de especialistas</span>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0 pt-0">
                                    <v-col cols="12">
                                        <div class="fill-height">
                                            <v-slide-group
                                                v-model="model"
                                                active-class="blue lighten-4"
                                                center-active
                                                class="pa-0"
                                                next-icon="mdi-arrow-right-drop-circle-outline"
                                                prev-icon="mdi-arrow-left-drop-circle-outline"
                                                show-arrows>
                                                <v-slide-item
                                                    v-for="card in doctors"
                                                    :key="card.id"
                                                    v-slot="{ active, toggle }">
                                                    <v-card v-if="card.pictures[0] != null"
                                                            :color="active ? undefined : 'white'"
                                                            class="ma-4 rounded-lg"
                                                            elevation="10"
                                                            height="auto"
                                                            :width="$vuetify.breakpoint.mobile ? 300 : 300"
                                                            @click="toggle">
                                                        <div v-on:click="getProfile(card.id)">
                                                            <v-img v-if="card.pictures[0] != null"
                                                                   :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                   :src="card.pictures[0].url"
                                                                   class="white--text align-end justify-start rounded-t-lg"
                                                                   height="200px"
                                                                   position="center">
                                                            </v-img>
                                                            <div class="row">
                                                                <div class="col-12 ">
                                                                    <v-card-subtitle
                                                                        class="pb-0 text-subtitle-1 indigo--text text-capitalize text-truncate">
                                                                        {{ card.name }}
                                                                    </v-card-subtitle>
                                                                </div>
                                                            </div>
                                                            <v-card-actions class="mt-1 justify-center">
                                                                <v-btn :href="card.facebook_profile"
                                                                       color="blue"
                                                                       icon
                                                                       target="_blank">
                                                                    <v-icon color="primary" size="24px">mdi-facebook
                                                                    </v-icon>
                                                                </v-btn>
                                                                <v-btn :href="card.instagram_profile"
                                                                       color="blue"
                                                                       icon
                                                                       target="_blank">
                                                                    <v-icon color="primary" size="24px">mdi-instagram
                                                                    </v-icon>
                                                                </v-btn>
                                                                <v-btn :href="card.tweeterprofile"
                                                                       color="blue"
                                                                       icon
                                                                       target="_blank">
                                                                    <v-icon color="primary" size="24px">mdi-twitter
                                                                    </v-icon>
                                                                </v-btn>
                                                                <v-btn :href="card.youtubeprofile"
                                                                       color="blue"
                                                                       icon
                                                                       target="_blank">
                                                                    <v-icon color="primary" size="24px">mdi-youtube
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </div>
                                                    </v-card>
                                                </v-slide-item>
                                            </v-slide-group>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-dialog
                v-model="dialog"
                max-width="350">
                <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <div class="row--dense mx-1 mt-2">
                            <v-text-field
                                v-model="email"
                                :rules="emailRules"
                                label="Correo electrónico"
                                required>
                            </v-text-field>
                        </div>
                        <v-card-actions class="pt-0">
                            <v-spacer></v-spacer>
                            <v-btn color="primary"
                                   text
                                   @click="closeDialog">
                                Cancelar
                            </v-btn>
                            <v-btn :disabled="!valid"
                                   color="primary"
                                   text
                                   @click="acceptDialog">
                                Aceptar
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </v-container>
        <footer-profile-component/>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import mapboxgl from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import doctorsService from '@/providers/DoctorsService';
    import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
    import preferencesService from '@/providers/PreferencesService';

    export default {
        name: "DoctorProfileComponent",
        components: {FooterProfileComponent},
        props: {
            doctor_id: Number,
            initialLocation: {
                type: Array,
                default: () => [-116.97480855890304, 32.547976944620714],
            },
            height: {
                type: String,
                default: "300px",
            },
            mapStyle: {
                type: String,
                default: "mapbox://styles/mapbox/streets-v11",
                // default: "mapbox://styles/mapbox/outdoors-v11",
            },
            color: {
                type: String,
                default: "orange",
            },
            containerID: {
                type: String,
                default: "map",
            },
        },
        data: () => ({
            model: null,
            youtubeVideo: '',
            snackBar: false,
            snackText: '',
            mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
            valid: true,
            dialog: false,
            email: null,
            location: [],
            doctors: [],
            profile: {
                id: 0,
                name: null,
                description: null,
                address: null,
                map_lng: null,
                map_lat: null,
                phone1: null,
                phone2: null,
                profesional_license_number: null,
                specialty_license_number: null,
                certification_validity: null,
                certificate_number: null,
                professional_license_verification: null,
                email: null,
                website: null,
                facebookprofile: null,
                instagramprofile: null,
                tweeterprofile: null,
                youtubeprofile: null,
                servicehours: null,
                pictures: [],
                specialties: [],
                certifications: []
            },
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
        }),
        computed: {
            containerCSS() {
                return {
                    width: "100%",
                    height: this.height,
                };
            },
        },
        filters: {
            formatPhone(phn, country) {
                // console.log('phn:', phn);
                if (phn == 'undefined' || phn == null || phn === '') {
                    return country;
                }
                switch (country) {
                    case "US":
                        return "USA +1 (" + phn.substring(0, 3) + ") " + phn.substring(3, 6) + "-" + phn.substring(6);
                    case "MX":
                        return "MEX +52 (" + phn.substring(0, 3) + ") " + phn.substring(3, 6) + "-" + phn.substring(6);
                    default:
                        return country;
                }
            },
        },
        methods: {
            goBack() {
                this.$router.push('/doctors');
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
            getDoctors() {
                doctorsService.getViewList().then(record => {
                    this.doctors = record.value;
                    // console.log("Medicos: ", this.doctors);
                });
            },
            loadURL() {
                const youtubeEmbedTemplate = 'https://www.youtube.com/embed/';
                const url = this.profile.youtubeprofile.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                // console.log("url", url);
                const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
                // console.log("YId", YId);
                if (YId === url[0]) {
                    console.log("not a youtube link");
                } else {
                    console.log("it's  a youtube video");
                }
                const topOfQueue = youtubeEmbedTemplate.concat(YId);
                // console.log("topOfQueue", topOfQueue);
                this.youtubeVideo = topOfQueue;
            },
            getProfile(idDoctor) {
                let record = null;
                doctorsService.getRecord(idDoctor).then(fetch_data => {
                    record = fetch_data.value;
                    // console.log('data:', record);
                    this.profile.id = record.id;
                    this.profile.name = record.name;
                    this.profile.description = record.description;
                    this.profile.address = record.address;
                    this.profile.map_lng = record.map_lng;
                    this.profile.map_lat = record.map_lat;
                    this.profile.email = record.email;
                    this.profile.phone1 = record.phone1;
                    this.profile.phone2 = record.phone2;
                    this.profile.profesional_license_number = record.profesional_license_number;
                    this.profile.specialty_license_number = record.specialty_license_number;
                    this.profile.certification_validity = record.certification_validity;
                    this.profile.certificate_number = record.certificate_number;
                    this.profile.professional_license_verification = record.professional_license_verification;
                    this.profile.website = record.web_page;
                    this.profile.facebookprofile = record.facebook_profile;
                    this.profile.instagramprofile = record.instagram_profile;
                    this.profile.tweeterprofile = record.twitter_profile;
                    this.profile.youtubeprofile = record.youtube_video;
                    this.profile.servicehours = record.servicehours;
                    this.profile.pictures = record.pictures;
                    this.profile.specialties = record.specialties;
                    this.profile.certifications = record.certifications;

                    // Gestion de la ubicacion en el mapa.
                    const lng = this.profile.map_lng;
                    const lat = this.profile.map_lat;
                    // console.log('lng: ', lng);
                    // console.log('lat: ', lat);
                    this.setLocationCoordinates({lng, lat});
                    this.createMap();

                    // console.log('profile:', this.profile);
                    if (this.profile.youtubeprofile != null) {
                        this.loadURL();
                    }
                    this.toTop();
                });
            },
            async createMap() {
                try {
                    mapboxgl.accessToken = this.mapBoxApiKey;
                    // Create map object
                    this.map = new mapboxgl.Map({
                        container: this.containerID,
                        style: this.mapStyle,
                        center: this.location,
                        zoom: 10,
                    });
                    // Controles basicos de navegacion: zoom y rotacion.
                    this.map.addControl(new mapboxgl.NavigationControl());

                    // Evento del mapa.
                    this.map.on('load', () => {
                        // console.log('here');
                        this.marker = new mapboxgl.Marker().setLngLat(this.location).addTo(this.map);
                        // console.log(this.marker);
                    });

                    // Add Click Listener
                    this.map.on("click", (event) => {
                        console.log('evento: ', event);
                        alert('Visitar el sitio oficial de MapBox');
                        // window.location.href = 'https://www.mapbox.com/';
                    });
                } catch (err) {
                    console.error("map error", err);
                }
            },
            setLocationCoordinates(lngLat) {
                this.location = [lngLat.lng, lngLat.lat];
            },
            sendPreference(tag_type) {
                let tagText = (tag_type === 'mark') ? "Me interesa" : "Me Gusta";
                preferencesService.addRecord(this.email, tag_type, 13).then(result => {
                    if (result.success) {
                        this.snackText = (tag_type === 'mark') ?
                            `Se ha enviado los datos de esta vista etiquedada con ${tagText}! a su buzón de correo!` :
                            `La vista ha sido etiquetada con ${tagText}!`;
                        // Enviar los datos por correo electronico.
                        doctorsService.sendEmail(this.profile.id, this.email).then(data => {
                            if (data.success) {
                                console.log('Correo enviado: ', data);
                            } else {
                                console.log('Error enviando correo: ', data);
                            }
                        });
                    } else {
                        this.snackText = 'Un error impidió etiquetar la vista!';
                    }
                    this.snackBar = true;
                });
            },
            openDialog() {
                this.email = '';
                this.dialog = true;
                //
            },
            closeDialog() {
                this.email = '';
                this.dialog = false;
            },
            acceptDialog() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    this.sendPreference('mark');
                    this.dialog = false;
                }
            }
        },
        mounted() {
            // this.interval = setInterval(() => this.updateLocation(), 60000);
            this.getDoctors();
            // this.doctor_id = this.$route.params.id;
            this.getProfile(this.$route.params.id);
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }

    .boxdivider {
        background-color: transparent;
        width: 100%;
        height: 15px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }

    .bottom-gradient {
        background-image: linear-gradient(to bottom,
        rgba(114, 217, 235, 0.185) 0%,
        rgba(101, 99, 248, 0.911) 100%);
    }

    .my-slider > .v-slide-group__next.theme--light.v-icon {
        color: rgb(234, 10, 10) !important;
        font-size: 24pt !important;
    }
</style>