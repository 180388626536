<template>
    <doctor-profile-component/>
</template>

<script>
    import DoctorProfileComponent from "@/components/medical-services/DoctorProfileComponent";
    
    export default {
        name: "DoctorProfile",
        components: {DoctorProfileComponent},
        title: "Perfil del Médicos | Turismo BC",
    }
</script>

<style scoped>

</style>